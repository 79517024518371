import React from 'react';
import './Grid.css';

const Grid = () => {
  const rows = 8;
  const columns = 8;
  const letters = ['A','B','C','D','E','F','G','H'];
  const emptyImagePath = '/pieces/empty.png';

  const gridSquares = [];

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < columns; col++) {
      const key = `${row}-${col}`;
      const idCol = letters[col];
      const idRow = (8-row);
      const color = (col+row)%2===0 ? "grid-square-light" : "grid-square-dark";
      let piecePath = '/.png';

      if(idRow === 7){
        piecePath = '/pieces/dark-pawn.png';
      }
      else if(idRow === 2){
        piecePath = '/pieces/light-pawn.png';
      }
      else if(idRow === 8 && idCol === 'E'){
        piecePath = '/pieces/dark-king.png';
      }
      else if(idRow === 8 && idCol === 'D'){
        piecePath = '/pieces/dark-queen.png';
      }
      else if(idRow === 8 && idCol === 'A'){
        piecePath = '/pieces/dark-rook.png';
      }
      else if(idRow === 8 && idCol === 'H'){
        piecePath = '/pieces/dark-rook.png';
      }
      else if(idRow === 8 && idCol === 'C'){
        piecePath = '/pieces/dark-bishop.png';
      }
      else if(idRow === 8 && idCol === 'F'){
        piecePath = '/pieces/dark-bishop.png';
      }
      else if(idRow === 8 && idCol === 'B'){
        piecePath = '/pieces/dark-knight.png';
      }
      else if(idRow === 8 && idCol === 'G'){
        piecePath = '/pieces/dark-knight.png';
      }
      else if(idRow === 1 && idCol === 'C'){
        piecePath = '/pieces/light-bishop.png';
      }
      else if(idRow === 1 && idCol === 'F'){
        piecePath = '/pieces/light-bishop.png';
      }
      else if(idRow === 1 && idCol === 'E'){
        piecePath = '/pieces/light-king.png';
      }
      else if(idRow === 1 && idCol === 'D'){
        piecePath = '/pieces/light-queen.png';
      }
      else if(idRow === 1 && idCol === 'A'){
        piecePath = '/pieces/light-rook.png';
      }
      else if(idRow === 1 && idCol === 'H'){
        piecePath = '/pieces/light-rook.png';
      }
      else if(idRow === 1 && idCol === 'B'){
        piecePath = '/pieces/light-knight.png';
      }
      else if(idRow === 1 && idCol === 'G'){
        piecePath = '/pieces/light-knight.png';
      }
      else{
        piecePath = null;
      }

      
      const chessPieceImage = piecePath;
      const gridSquare = (
      <div key={key} className={color}>
        {chessPieceImage === emptyImagePath ? null : (
        <img src={chessPieceImage} alt="" className="chess-piece-image" />
        )}
        {idCol}{idRow}
      </div>
        );
      gridSquares.push(gridSquare);
    }
  }

  return (
    <div className="grid-container">
      {gridSquares}
    </div>
  );
};

export default Grid;
